<template>
  <base-section id="aboutourproduct">
    <base-section-heading
      color="grey lighten-2"
      title="Servicios"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',
    data: () => ({
      cards: [
        {
          title: 'Timbres',
          // subtitle: 'Subtitulo',
          text: 'Son dispositivos de señalización que normalmente se instala en o cerca de la puerta de entrada de una casa o edificio',
          callout: '1',
        },
        {
          title: 'Instalación de lámparas',
          // subtitle: 'Subtitulo',
          text: 'Son dispositivos eléctricos que producen luz a partir de energía eléctrica. Esta conversión puede realizarse mediante distintos métodos como el calentamiento por efecto Joule de un filamento metálico.',
          callout: '2',
        },
        {
          title: 'Halógenos',
          // subtitle: 'Subtitulo',
          text: 'Son una evolución de la lámpara incandescente con un filamento de Wolframio dentro de un gas inerte y una pequeña cantidad de halógeno (como yodo o bromo).',
          callout: '3',
        },
        {
          title: 'Revisión de tu cuadro eléctrico',
          // subtitle: 'Subtitulo',
          text: 'El cuadro eléctrico es un elemento clave de la instalación eléctrica, y saber cómo funciona y cuáles son sus componentes puede resultar vital en caso de incidencia.',
          callout: '4',
        },
        {
          title: 'Punto de instalaciones eléctricas',
          // subtitle: 'Subtitulo',
          text: 'Son un conjunto de circuitos eléctricos que tiene como objetivo dotar de energía eléctrica a edificios, instalaciones, lugares públicos, infraestructuras, etc. Incluye los equipos necesarios para asegurar su correcto funcionamiento y la conexión con los aparatos eléctricos correspondientes.',
          callout: '5',
        },
        {
          title: 'Ticinos',
          // subtitle: 'Subtitulo',
          text: 'Es una lámina de hierro, con perforaciones para distintos diámetros de tuberías. Los componentes metálicos son sometidos a un proceso de pintura que garantiza la uniformidad de la superficie y la máxima resistencia a la corrosión, manipulación y agentes atmosféricos.',
          callout: '6',
        },
        {
          title: 'Portero y video portero',
          // subtitle: 'Subtitulo',
          text: 'Son sistemas autónomos que sirven para gestionar las llamadas que se hacen en la puerta de un edificio (sea complejo residencial, vivienda unifamiliar, centros de trabajo, etc).',
          callout: '7',
        },
      ],
    }),
  }
</script>
